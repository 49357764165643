import * as React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

import { LogoJsonLd } from 'gatsby-plugin-next-seo';

import "./normalize.css"
import "./index.scss"

import Header from "../components/header/header"
import Menu from "../components/menu/menu"
import Bloglist from "../components/bloglist/bloglist"
import Contact from "../components/contact/contact"
import Team from "../components/team/team"
import Footer from "../components/footer/footer"

import ShareLogo from "../../static/logo-tanecni-zlonin.png"

const IndexPage = () => {
	return (

		<Layout>
			<Seo title="Taneční kurzy pro dospělé, Latin Fever a Tančírna" />

			<LogoJsonLd
				logo={ShareLogo}
				url='https://www.tanecni-zlonin.cz'
			/>

			<Header></Header>
			<Menu></Menu>
			<Bloglist></Bloglist>
			<Contact></Contact>
			<Team></Team>
			<Footer></Footer>

		</Layout>

	)
}

export default IndexPage