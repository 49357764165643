import React from "react"

import "./team.scss"
import "../../pages/index.scss"

import ImgTeam1 from "../../images/team1.jpg"
import ImgTeam2 from "../../images/team2.jpg"
import ImgTeam3 from "../../images/team3.jpg"
import ImgTeam4 from "../../images/team4.jpg"
import ImgTeam5 from "../../images/team5.jpg"
import ImgTeam6 from "../../images/team6.jpg"

import ImgLogo1 from "../../images/logo-sut.png"
import ImgLogo2 from "../../images/logo-wdc.png"
import ImgLogo3 from "../../images/logo-wdo.png"

const Team = () => (

	<div className="tz-team">

		<div className="container">

			<h2 className="mb3" data-sal="fade" data-sal-duration="1000">Kdo jsme?</h2>

			<div className="container-50">
				<div className="tz-team__photos" data-sal="fade" data-sal-duration="1000">
					<img src={ImgTeam1} alt="Jaroslav Suchý - Irina Maizlish" className="tz-team__photo" />
					<img src={ImgTeam2} alt="Jaroslav Suchý - Irina Maizlish" className="tz-team__photo" />
					<img src={ImgTeam3} alt="Jaroslav Suchý - Irina Maizlish" className="tz-team__photo" />
					<img src={ImgTeam4} alt="Jaroslav Suchý - Irina Maizlish" className="tz-team__photo" />
					<img src={ImgTeam5} alt="Jaroslav Suchý - Irina Maizlish" className="tz-team__photo" />
					<img src={ImgTeam6} alt="Jaroslav Suchý - Irina Maizlish" className="tz-team__photo" />
				</div>
				<div className="tz-team__article" data-sal="fade" data-sal-duration="1000">
					<h3 className="mt0">Jaroslav Suchý &amp; Irina Suchý Maizlish</h3>
					<p>Většinu svého života se věnujeme tanci. Ať&nbsp;už jako profesionální soutěžní taneční pár nebo jako lektoři kurzů a&nbsp;individuálních lekcí pro&nbsp;soutěžní taneční páry a&nbsp;veřejnost.</p>
					<p>Jsme rádi, že&nbsp;vás můžeme přivítat do&nbsp;tanečního kurzu pro&nbsp;dospělé ve&nbsp;Zloníně, kde už&nbsp;více než&nbsp;10&nbsp;let žijeme.</p>
					<p>Těšíme se na vás.<br />Jarda &amp; Irina</p>
				</div>
			</div>

			<h3 className="tz-team__membership-header" data-sal="fade" data-sal-duration="1000">Jsme členy tanečních organizací:</h3>
			<ul className="tz-team__membership">
				<li className="tz-team__membership-item" data-sal="fade" data-sal-duration="1000">
					<a href="http://www.sut.cz" target="_blank" rel="noreferrer" className="tz-team__membership-link">
						<img src={ImgLogo1} alt="SUT - Svaz Učitelů Tance" className="tz-team__logo" />
						<span className="tz-team__membership-text">SUT<br />Svaz učitelů tance</span>
					</a>
				</li>
				<li className="tz-team__membership-item" data-sal="fade" data-sal-duration="1000">
					<a href="https://www.wdcdance.com" target="_blank" rel="noreferrer" className="tz-team__membership-link">
						<img src={ImgLogo2} alt="WDC - World Dance Council" className="tz-team__logo" />
						<span className="tz-team__membership-text">WDC<br />World Dance Council</span>
					</a>
				</li>
				<li className="tz-team__membership-item" data-sal="fade" data-sal-duration="1000">
					<a href="https://www.worlddanceorganisation.com" target="_blank" rel="noreferrer" className="tz-team__membership-link">
						<img src={ImgLogo3} alt="WDO - World Dance Organization" className="tz-team__logo" />
						<span className="tz-team__membership-text">WDO<br />World Dance Organization</span>
					</a>
				</li>
			</ul>

		</div>

	</div>

)

export default Team