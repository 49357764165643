import React from "react"

import "./header.scss"
import "../../pages/index.scss"

import ImgLogo from "../../images/logo-tanecni-zlonin.svg"
import Img1 from "../../images/web-slideshow-1.jpg"
import Img2 from "../../images/web-slideshow-2.jpg"
import Img3 from "../../images/web-slideshow-3.jpg"

const Header = () => (
  <div className="tz-header">
    <div className="container tz-header__container">
      <img src={ImgLogo} alt="Taneční Zlonín logo" className="tz-header__logo"  data-sal="fade" data-sal-duration="1000" />

      <h1 className="tz-header__h1" data-sal="fade" data-sal-duration="1000">Taneční kurzy ve&nbsp;Zloníně</h1>
    </div>

    <div className="tz-header__slideshow">
      <div className="tz-header__slide" style={{ backgroundImage: `url(${Img1})` }}></div>
      <div className="tz-header__slide" style={{ backgroundImage: `url(${Img2})` }}></div>
      <div className="tz-header__slide" style={{ backgroundImage: `url(${Img3})` }}></div>
    </div>
  </div>
)

export default Header
